import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Animation, Audios } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

const DATA_CHAT = () => {
  const PageId = 119;
  const PreviousPageId = PageId - 1;
  const NextPageId = PageId + 1;
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const path = "/images/management/19_chat/chat_";

  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}1.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}2.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}3.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}4.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}5.png`, imageStyle: { position: "static", width: "100%" } },
  ];

  const times = [0, 600, 2000, 2000, 2000];

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  const pathAudios = `${Config.BASE_URL}/audios/management/`;
  const AUDIOS = [{ src: `${pathAudios}screen_29.mp3`, timer: 0 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_4.png`,
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
    chatImages: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_CHAT;
