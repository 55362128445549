import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingJson from "./loading.json";

const Loading = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading && (
        <div style={styles.loading}>
          <Lottie loop animationData={loadingJson} play style={{ width: 100, height: 100, marginBottom: "100px" }} />
        </div>
      )}
    </>
  );
};

const styles = {
  loading: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
};

export default Loading;
