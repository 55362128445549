import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Animation } from "@yamildiego/presentation-kit";
import { Audios } from "@yamildiego/presentation-kit";

const DATA_CHAT = () => {
  const PageId = 128;
  const NextPageId = PageId + 1;
  const PreviousPageId = PageId - 1;

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const path = "/images/management/28_chat";

  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
  ];

  const times = [600];

  const pathAudios = `${Config.BASE_URL}/audios/management/`;
  const AUDIOS = [{ src: `${pathAudios}screen_43.mp3`, timer: 1000 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_10.png`,
    chatImages: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
  };
};

export default DATA_CHAT;
