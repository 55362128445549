import { TemplatesAnimation, useResizeBase, ScreenOrientation } from "@yamildiego/presentation-kit";

import INTRO from "src/Data/INTRO/DEMO_WORKFLOW";
import MANAGEMENT from "src/Data/MANAGEMENT/DEMO_WORKFLOW";

import connectWithDispatch from "src/Hooks/connectWithDispatch";
import * as appActions from "src/Actions/appActions";
import { useEffect } from "react";

interface AppProps {
  currentPage: number;
  muted: boolean;
  setMuted: (value: boolean) => void;
  setCurrentPage: (value: number) => void;
}

const App = (props: AppProps) => {
  useResizeBase();
  const { currentPage, setMuted, muted } = props;

  const toggleMuted = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    const pages = [...INTRO, ...MANAGEMENT];
    const currentIndex = pages.findIndex((page) => page.page === currentPage);

    const handleKeyUp = (event: any) => {
      if (event.key === "ArrowLeft") {
        if (currentIndex === -1 || currentIndex === 0) return;
        else props.setCurrentPage(pages[currentIndex - 1].page);
      } else if (event.key === "ArrowRight") {
        if (currentIndex === -1 || !pages[currentIndex + 1]) return;
        else props.setCurrentPage(pages[currentIndex + 1].page);
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <div style={{ position: "absolute", top: 0, left: 0, color: "red", zIndex: 100 }}>{currentPage}</div>
      <ScreenOrientation>
        <TemplatesAnimation currentPage={currentPage} templates={INTRO} />
        <TemplatesAnimation muted={muted} toggleMuted={toggleMuted} currentPage={currentPage} templates={MANAGEMENT} />
      </ScreenOrientation>
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    currentPage: state.appReducer.currentPage,
    muted: state.appReducer.muted,
  };
};

const mapDispatchToProps = {
  setMuted: appActions.setMuted,
  setCurrentPage: appActions.setCurrentPage,
};

export default connectWithDispatch(App, mapStateToProps, mapDispatchToProps);
