import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

const PageId = 101;
const PreviousPageId = 0;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:42am",
      },
    ],
    text: "Meet Anisha, a 25-year-old\nsoftware engineer and loyal\nAmerican Express cardholder.\nShe is a frequent traveller\nwho values security and\nswift resolutions.",
    rightSideImage: `${Config.BASE_URL}/images/management/customer.png`,
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
  };
};

export default DATA_JOURNEY_ONE;
