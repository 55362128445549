import "./index.sass";

const HeaderRight = () => {
  return (
    <div className="header-right">
      <div className="title">Fraud</div>
      <div className="subtitle">Management</div>
    </div>
  );
};

export default HeaderRight;
