import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Audios } from "@yamildiego/presentation-kit";

const PageId = 134;
const PreviousPageId = PageId - 1;
const NextPageId = 0;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const home = `${Config.BASE_URL}/images/icons/home.png`;

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 1200);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity 1s ease-in-out",
    height: "100%",
  };

  const rightSideImage1 = `${Config.BASE_URL}/images/management/34_screen/right_side_1.png`;

  const [styleButtons, setStyleButtons] = useState({});

  useEffect(() => {
    setStyleButtons({
      transition: "opacity 1s ease-in-out",
      opacity: step >= 1 ? 1 : 0,
      cursor: step >= 1 ? "pointer" : "default",
    });
  }, [step]);

  const pathAudios = `${Config.BASE_URL}/audios/management/`;
  const AUDIOS = [{ src: `${pathAudios}screen_51.mp3`, timer: 1200 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:45am",
      },
    ],
    text: "Information will be provided\nseamlessly via the customer’s\npreferred channels and devices,\nallowing for continuous support.",

    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 1 ? 1 : 0 }} />
      </>
    ),
    back: {
      label: "Back",
      type: "back",
      startIcon: arrowLeft,
      onClick: () => {
        if (step >= 1) setCurrentPage(PreviousPageId);
      },
      style: styleButtons,
    },
    next: {
      label: "Home",
      type: "next",
      startIcon: home,
      onClick: () => {
        if (step >= 1) setCurrentPage(NextPageId);
      },
      style: styleButtons,
    },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_JOURNEY_ONE;
