import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Audios } from "@yamildiego/presentation-kit";

const PageId = 108;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  const [styleButtons, setStyleButtons] = useState({});

  useEffect(() => {
    setStyleButtons({
      transition: "opacity 1s ease-in-out",
      opacity: step > 1 ? 1 : 0,
      cursor: step > 1 ? "pointer" : "default",
    });
  }, [step]);

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 600);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setStep(step + 1), 3000);
    if (step === 2) setTimeout(() => setStep(step + 1), 400);
    if (step === 3) setTimeout(() => setStep(step + 1), 400);
    if (step === 4) setTimeout(() => setStep(step + 1), 400);
    if (step === 5) setTimeout(() => setStep(step + 1), 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity 1s ease-in-out",
    height: "100%",
  };

  const rightSideImage1 = `${Config.BASE_URL}/images/management/customer.png`;
  const rightSideImage2 = `${Config.BASE_URL}/images/management/08_screen.png`;
  const rightSideImage3 = `${Config.BASE_URL}/images/management/08_screen_1.png`;
  const rightSideImage4 = `${Config.BASE_URL}/images/management/08_screen_2.png`;
  const rightSideImage5 = `${Config.BASE_URL}/images/management/08_screen_3.png`;
  const rightSideImage6 = `${Config.BASE_URL}/images/management/08_screen_4.png`;

  const pathAudios = `${Config.BASE_URL}/audios/management/`;
  const AUDIOS = [{ src: `${pathAudios}screen_15.mp3`, timer: 3200 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:46am",
      },
    ],
    text: "Anisha enters her security PIN\nduring the call to finalise\nauthentication.",
    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 1 ? 1 : 0 }} />
        <img src={rightSideImage2} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 2 ? 1 : 0 }} />
        <img src={rightSideImage3} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 3 ? 1 : 0 }} />
        <img src={rightSideImage4} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 4 ? 1 : 0 }} />
        <img src={rightSideImage5} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 5 ? 1 : 0 }} />
        <img src={rightSideImage6} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 6 ? 1 : 0 }} />
      </>
    ),
    back: {
      label: "Back",
      type: "back",
      startIcon: arrowLeft,
      onClick: () => {
        if (step > 1) setCurrentPage(PreviousPageId);
      },
      style: styleButtons,
    },
    next: {
      label: "Next",
      type: "next",
      endIcon: arrowRight,
      onClick: () => {
        if (step > 1) setCurrentPage(NextPageId);
      },
      style: styleButtons,
    },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_JOURNEY_ONE;
