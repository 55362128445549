import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Audios } from "@yamildiego/presentation-kit";

const PageId = 125;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 4000);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setStep(step + 1), 3000);
    if (step === 2) setTimeout(() => setStep(step + 1), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity 1s ease-in-out",
    height: "100%",
  };

  const rightSideImage1 = `${Config.BASE_URL}/images/management/25_screen/right_side_1.png`;
  const rightSideImage2 = `${Config.BASE_URL}/images/management/25_screen/right_side_2.png`;
  const rightSideImage3 = `${Config.BASE_URL}/images/management/25_screen/right_side_3.png`;

  const pathAudios = `${Config.BASE_URL}/audios/Sound effects/`;
  const AUDIOS = [{ src: `${pathAudios}Notification 2 - Mountain Audio - Bell Notification.mp3`, timer: 4800 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_3.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Gurgaon",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:44am",
      },
    ],
    text:
      step === 3
        ? "The analysis can even prioritise\ncalls based on the emotional\nstate of the customer."
        : "Anisha is being connected to\nRahul. A summary of the chat is\nprovided with a Natural\nLanguage Processing (NLP) tool\nused to assess the tone and\nsentiment of her responses.\n\nThe analysis provides critical\ninformation before speaking to\nAnisha.",
    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 1 ? 1 : 0 }} />
        <img src={rightSideImage2} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 2 ? 1 : 0 }} />
        <img src={rightSideImage3} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 3 ? 1 : 0 }} />
      </>
    ),
    back: {
      label: "Back",
      type: "back",
      startIcon: arrowLeft,
      onClick: () => setCurrentPage(PreviousPageId),
      style: { transition: "opacity 1s ease-in-out", opacity: step >= 3 ? 1 : 0 },
    },
    next: {
      label: "Next",
      type: "next",
      endIcon: arrowRight,
      onClick: () => setCurrentPage(NextPageId),
      style: { transition: "opacity 1s ease-in-out", opacity: step >= 3 ? 1 : 0 },
    },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_JOURNEY_ONE;
