import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Audios } from "@yamildiego/presentation-kit";

const PageId = 131;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 600);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setStep(step + 1), 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity 1s ease-in-out",
    height: "100%",
  };

  const rightSideImage1 = `${Config.BASE_URL}/images/management/31_screen/right_side_1.png`;
  const rightSideImage2 = `${Config.BASE_URL}/images/management/31_screen/phone_3.png`;

  const pathAudios = `${Config.BASE_URL}/audios/Sound effects/`;
  const AUDIOS = [{ src: `${pathAudios}Notification 1 - Short Marimba Notification Ding.mp3`, timer: 700 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:42am",
      },
    ],
    text: "The future service will also\nenable a seamless, agentless\nsupport experience.",

    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 1 ? 1 : 0 }} />
        <img
          src={rightSideImage2}
          alt=""
          style={{ ...rightSideComponentBaseStyle, opacity: step >= 2 ? 1 : 0, zIndex: step === 2 ? 100 : 0, cursor: "pointer" }}
          onClick={() => setCurrentPage(NextPageId)}
        />
      </>
    ),
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_JOURNEY_ONE;
