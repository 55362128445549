import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import setCurrentPage from "src/Utils/setCurrentPage";
import Config from "src/Constants/Config";
import store from "src/Reducers/store";
import { Audios } from "@yamildiego/presentation-kit";

const PageId = 123;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 600);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setStep(step + 1), 1200);
    if (step === 2) setTimeout(() => setStep(step + 1), 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity .6s ease-in-out",
    height: "100%",
  };

  const rightSideImage1 = `${Config.BASE_URL}/images/management/customer.png`;
  const rightSideImage2 = `${Config.BASE_URL}/images/management/23_screen/right_side_2.png`;
  const rightSideImage3 = `${Config.BASE_URL}/images/management/23_screen/phone_3.png`;

  // const [divBottonStyle, setDivBottonStyle] = useState<React.CSSProperties>({
  //   cursor: "pointer",
  //   zIndex: 1000,
  //   right: window.innerWidth > 1920 ? `calc(41vw - ${(window.innerWidth - 1920) / 2}px)` : "41vw",
  //   position: "absolute",
  //   top: window.innerWidth > 1920 ? "44vh" : "30vw",
  //   padding: "3.3vw",
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //     setDivBottonStyle({
  //       cursor: "pointer",
  //       zIndex: 1000,
  //       right: window.innerWidth > 1920 ? `calc(41vw - ${(window.innerWidth - 1920) / 2}px)` : "41vw",
  //       position: "absolute",
  //       top: window.innerWidth > 1920 ? "44vh" : "30vw",
  //       padding: "3.3vw",
  //     });
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const pathAudios = `${Config.BASE_URL}/audios/Sound effects/`;
  const AUDIOS = [{ src: `${pathAudios}Notification 1 - Short Marimba Notification Ding.mp3`, timer: 1500 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:42am",
      },
    ],
    text: "American Express detects a\nsuspicious transaction of ₹8,000 with\nFlipkart on Anisha's account.\n\nThe AI system immediately triggers a\nnotification to Anisha and uses a\nNatural Language Processing (NLP)\nmodel to assess the tone and\nsentiment of her responses. ",
    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 1 ? 1 : 0 }} />
        <img src={rightSideImage2} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step >= 2 ? 1 : 0 }} />
        <img
          src={rightSideImage3}
          alt=""
          onClick={() => setCurrentPage(NextPageId)}
          style={{ ...rightSideComponentBaseStyle, opacity: step === 3 ? 1 : 0, zIndex: step === 3 ? 1000 : 0, cursor: "pointer" }}
        />
      </>
    ),
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_JOURNEY_ONE;
