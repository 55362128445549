import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Config from "src/Constants/Config";
import store from "src/Reducers/store";

import setCurrentPage from "src/Utils/setCurrentPage";

const PageId = 109;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 100);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const leftSideEmbeddedComponentSrc1 = `${Config.BASE_URL}/images/management/07_screen/boxs.png`;

  const imagesEmbeddedBaseStyle: React.CSSProperties = {
    position: "absolute",
    left: 0,
    bottom: 0,
    transition: "opacity 1s ease-in-out",
    width: "fit-content",
  };

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:46am",
      },
    ],
    text: "Anisha enters her security PIN\nduring the call to finalise\nauthentication.",
    rightSideImage: `${Config.BASE_URL}/images/management/09_screen.png`,
    leftSideEmbeddedComponent: (
      <>
        <div style={{ position: "relative" }}>
          <img src={leftSideEmbeddedComponentSrc1} alt="" style={{ ...imagesEmbeddedBaseStyle, opacity: step >= 1 ? 1 : 0 }} />
        </div>
      </>
    ),
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
  };
};

export default DATA_JOURNEY_ONE;
