import "./index.sass";
import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const HeaderCenter = () => {
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const [title, setTitle] = useState<string>("Everyday Greatness");

  useEffect(() => {
    if (currentPage >= 122) {
      if (currentPage <= 130) setTitle("Next");
      else setTitle("North");
    } else setTitle("Everyday Greatness");
  }, [currentPage]);

  return (
    <div className="header-center">
      <div className="title">project atom</div>
      <div className="subtitle">{title}</div>
    </div>
  );
};

export default HeaderCenter;
