import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";

import store from "./Reducers/store";
import { ThemeProvider } from "@yamildiego/presentation-kit";

import "./Assets/css/index.css";
import "@yamildiego/presentation-kit/dist/Assets/Fonts/index.css";

import setCurrentPage from "src/Utils/setCurrentPage";
import Loading from "./Component/Loading";
import Logo from "./Component/Logo";
import HeaderCenter from "./Component/HeaderCenter";
import HeaderRight from "./Component/HeaderRight";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const data = {
  base: {
    showLineOne: false,
    showLineTwo: true,
    labelStyle: {
      background: "transparent",
      border: "1px solid #DF9926",
      borderRadius: "5px",
    },
    baseTextStyle: {
      fontSize: "2.25rem",
    },
    showHeaderShadow: true,
  },
  template: {
    "journey-one-template": {
      styleDescription: {
        marginTop: "2.31vh",
      },
    },
  },
  Button: {
    base: {
      fontSize: "1.5rem",
      background: "yellow",
      borderRadius: "5px",
      padding: "1.39vh 0.52vw",
    },
    next: {
      background: "#016FD0",
      border: "1px solid #016FD0",
      color: "white",
    },
    back: {
      background: "transparent",
      border: "1px solid white",
      color: "white",
    },
  },
};

const globalData = {
  header: {
    logo: <Logo />,
    rightSide: <HeaderRight />,
    centerSide: <HeaderCenter />,
    onClick: () => setCurrentPage(0),
    styleBGShadow: {
      opacity: "0.8",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.90) 41%, rgba(0, 0, 0, 0.00) 100%)",
    },
  },
};

root.render(
  <Provider store={store}>
    <Loading />
    <ThemeProvider themeStyles={data} globalData={globalData}>
      <App />
    </ThemeProvider>
  </Provider>
);
