import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const PageId = 107;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const [step, setStep] = useState(0);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  const [styleButtons, setStyleButtons] = useState({});

  useEffect(() => {
    setStyleButtons({
      transition: "opacity .6s ease-in-out",
      opacity: step > 1 ? 1 : 0,
      cursor: step > 1 ? "pointer" : "default",
    });
  }, [step]);

  useEffect(() => {
    if (currentPage === PageId) {
      if (step === 0) setTimeout(() => setStep(step + 1), 600);
    } else setStep(0);

    return () => {
      setStep(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setStep(step + 1), 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const rightSideComponentBaseStyle: React.CSSProperties = {
    position: "absolute",
    right: 0,
    bottom: 0,
    transition: "opacity .6s ease-in-out",
    height: "100%",
  };

  const imagesEmbeddedBaseStyle: React.CSSProperties = {
    position: "absolute",
    left: 0,
    bottom: 0,
    transition: "opacity .6s ease-in-out",
    width: "fit-content",
  };

  const leftSideEmbeddedComponentSrc1 = `${Config.BASE_URL}/images/management/07_screen/boxs.png`;

  const rightSideImage1 = `${Config.BASE_URL}/images/management/07_screen/right_side_1.png`;
  const rightSideImage2 = `${Config.BASE_URL}/images/management/07_screen/right_side_2.png`;

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_3.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Gurgaon",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:45am",
      },
    ],
    text: "Rahul asks Anisha to enter her\nsecurity PIN to authenticate the\ninformation against the Fiserv\nIdentity and Verification system.",
    leftSideEmbeddedComponent: (
      <>
        <div style={{ position: "relative" }}>
          <img src={leftSideEmbeddedComponentSrc1} alt="" style={{ ...imagesEmbeddedBaseStyle, opacity: step >= 1 ? 1 : 0 }} />
        </div>
      </>
    ),
    rightSideComponent: (
      <>
        <img src={rightSideImage1} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 1 ? 1 : 0 }} />
        <img src={rightSideImage2} alt="" style={{ ...rightSideComponentBaseStyle, opacity: step === 2 ? 1 : 0 }} />
      </>
    ),
    back: {
      label: "Back",
      type: "back",
      startIcon: arrowLeft,
      onClick: () => {
        if (step > 1) setCurrentPage(PreviousPageId);
      },
      style: styleButtons,
    },
    next: {
      label: "Next",
      type: "next",
      endIcon: arrowRight,
      onClick: () => {
        if (step > 1) setCurrentPage(NextPageId);
      },
      style: styleButtons,
    },
  };
};

export default DATA_JOURNEY_ONE;
