import { TemplateType } from "@yamildiego/presentation-kit";

import DATA_JOURNEY_ONE_01 from "./01DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_02 from "./02DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_04 from "./04DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_05 from "./05DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_055 from "./05DATA_JOURNEY_ONE5";
import DATA_CHAT_06 from "./06DATA_CHAT";
import DATA_JOURNEY_ONE_07 from "./07DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_08 from "./08DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_09 from "./09DATA_JOURNEY_ONE";
import DATA_CHAT_10 from "./10DATA_CHAT";
import DATA_JOURNEY_ONE_11 from "./11DATA_JOURNEY_ONE";
import DATA_CHAT_12 from "./12DATA_CHAT";
import DATA_CHAT_13 from "./13DATA_CHAT";
import DATA_CHAT_14 from "./14DATA_CHAT";
import DATA_CHAT_15 from "./15DATA_CHAT";
import DATA_CHAT_16 from "./16DATA_CHAT";
import DATA_CHAT_17 from "./17DATA_CHAT";
import DATA_CHAT_18 from "./18DATA_CHAT";
import DATA_CHAT_19 from "./19DATA_CHAT";
import DATA_CHAT_20 from "./20DATA_CHAT";
import DATA_CHAT_21 from "./21DATA_CHAT";
import DATA_INTRO_22 from "./22DATA_INTRO";
import DATA_JOURNEY_ONE_23 from "./23DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_24 from "./24DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_25 from "./25DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_26 from "./26DATA_JOURNEY_ONE";
import DATA_CHAT_27 from "./27DATA_CHAT";
import DATA_JOURNEY_ONE28 from "./28DATA_JOURNEY_ONE";
import DATA_CHAT_29 from "./29DATA_CHAT";
import DATA_JOURNEY_ONE_30 from "./30DATA_JOURNEY_ONE";
import DATA_INTRO_305 from "./30DATA_INTRO5";
import DATA_JOURNEY_ONE_31 from "./31DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_32 from "./32DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_33 from "./33DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_34 from "./34DATA_JOURNEY_ONE";

const DEMO_WORKFLOW = [
  { page: 101, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_01 },
  { page: 102, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_02 },
  { page: 104, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_04 },
  { page: 105, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_05 },
  { page: 105.5, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_055 },
  { page: 106, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_06 },
  { page: 107, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_07 },
  { page: 108, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_08 },
  { page: 109, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_09 },
  { page: 110, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_10 },
  { page: 111, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_11 },
  { page: 112, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_12 },
  { page: 113, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_13 },
  { page: 114, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_14 },
  { page: 115, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_15 },
  { page: 116, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_16 },
  { page: 117, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_17 },
  { page: 118, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_18 },
  { page: 119, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_19 },
  { page: 120, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_20 },
  { page: 121, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_21 },
  { page: 122, type: "base-template" as TemplateType, dataTemplate: DATA_INTRO_22 },
  { page: 123, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_23 },
  { page: 124, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_24 },
  { page: 125, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_25 },
  { page: 126, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_26 },
  { page: 127, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_27 },
  { page: 128, type: "chat-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE28 },
  { page: 129, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_29 },
  { page: 130, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_30 },
  { page: 130.5, type: "base-template" as TemplateType, dataTemplate: DATA_INTRO_305 },
  { page: 131, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_31 },
  { page: 132, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_32 },
  { page: 133, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_33 },
  { page: 134, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_34 },
];

export default DEMO_WORKFLOW;
