import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Audios } from "@yamildiego/presentation-kit";

const DATA_CHAT = () => {
  const PageId = 113;
  const NextPageId = PageId + 1;
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (currentPage === PageId) {
      timeoutRef.current = setTimeout(() => {
        if (currentPage === PageId) {
          setCurrentPage(NextPageId);
        }
      }, 4000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentPage, PageId, NextPageId]);

  const pathAudios = `${Config.BASE_URL}/audios/Sound effects/`;
  const AUDIOS = [{ src: `${pathAudios}Notification 1 - Short Marimba Notification Ding.mp3`, timer: 10 }];
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_5.png`,
    back: null,
    next: null,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_CHAT;
