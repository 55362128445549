import Config from "src/Constants/Config";
import store from "src/Reducers/store";
import { useSelector } from "react-redux";
import setCurrentPage from "src/Utils/setCurrentPage";
import { Audios } from "@yamildiego/presentation-kit";

const DATA_CHAT = () => {
  const PageId = 118;
  const PreviousPageId = PageId - 3;
  const NextPageId = PageId + 1;

  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  const pathAudios = `${Config.BASE_URL}/audios/Sound effects/`;
  const AUDIOS = [{ src: `${pathAudios}Notification 1 - Short Marimba Notification Ding.mp3`, timer: 10 }];
  const muted = useSelector(() => store.getState().appReducer.muted);
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_7.png`,
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_CHAT;
