import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

const PageId = 104;
const PreviousPageId = PageId - 2;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const arrowLeft = `${Config.BASE_URL}/images/icons/arrow-left.png`;
  const arrowRight = `${Config.BASE_URL}/images/icons/arrow-right.png`;

  return {
    bgImage: `${Config.BASE_URL}/images/management/bg_2.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Mumbai",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "08:42am",
      },
    ],
    text: "After receiving the message,\nAnisha checks if there are any\nother suspicious transactions in\nher American Express app.",
    back: { label: "Back", type: "back", startIcon: arrowLeft, onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Next", type: "next", endIcon: arrowRight, onClick: () => setCurrentPage(NextPageId) },
    rightSideImage: `${Config.BASE_URL}/images/management/04_phone.png`,
  };
};

export default DATA_JOURNEY_ONE;
